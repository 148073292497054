<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                    <div class="row">
                        <ul class="collection with-header">
                            <li class="collection-item col s12 left-align no-padding clickable" v-for="(item, index) in customer_services" :key="index" v-on:click.prevent="viewService(index)">
                                <div class="col s12 padding-top-1 padding-bottom-1 padding-right-half padding-left-half valign-wrapper homeboy-font homeboy-charcoal-black-text">
                                    <div class="col s10 no-padding"><span class="vert-offset-left-half font-20" >{{item.name}}</span></div>
                                    <div class="col s2 no-padding right-align"><i class="material-icons right">navigate_next</i></div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import http from "../../http-common"


    export default {
        name: 'Customer-Home',
        data(){
            return{
                customer_services:""
            }
        },

        created(){
            this.getCustomerServices();
            this.loadPage();
        },
        methods:{
            loadPage: function()
            {
                this.$setPageHeader();
                this.loadMenu();
            },
            loadMenu: function()
            {
                let _arrMenu = [
                    {
                        title : "Find Supplier",
                        link: "/search/supplier",
                        icon: "search"
                    },
                    {
                        title : "Edit Profile",
                        link: "/update/profile",
                        icon:"person"
                    },
                    {
                        title : "Change Pin",
                        link: "/change/pin",
                        icon:"lock"
                    },

                ];
                this.$store.dispatch('COMMIT_SET_MENU_LINKS', _arrMenu);
            },
            getCustomerServices() {
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: '/customers/app/list',
                        method: 'POST',
                        timeout: 30000
                    }).then
                    (resp => {


                        this.$store.dispatch('STOP_PROCESSING');
                        this.customer_services = resp.data.data;
                        this.$store.dispatch('COMMIT_SET_SERVICES_LIST', this.customer_services);
                        console.log("customer services: "+JSON.stringify(this.customer_services));

                        if(typeof this.customer_services !== "undefined" && this.customer_services !== null && this.customer_services.length === 1)
                        {
                            this.viewService(0);
                        }


                        resolve(resp)
                    })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response.data));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },
            forgotPassword() {

            },
            viewService(service){

                const selected_service = this.customer_services[service];
                console.log("Selected Service: "+ JSON.stringify(selected_service));

                this.$store.dispatch('CUSTOMER_SELECTED_SERVICE', selected_service).then(() =>
                {
                    this.$router.push('/view/service')
                });


            }
        }
    }
</script>
